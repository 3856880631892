<template>

  <div class="row row-cards">
    <div class="col-md-9">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Auswertung</h3>
        </div>
        <div class="card-body border-bottom py-3">
          <div class="d-flex">
            <div class="text-muted">
            </div>
            <div class="ms-auto text-muted d-flex ">
              <select class="form-select me-2" v-if="!loading_user">
                <option value="">Alle Nutzer</option>
                <option v-for="index in data_filter" :key="index.id" :value="index.id">{{index.name}}</option>
              </select>
              <select v-model="stage_id" class="form-select me-2" v-if="!loading_stages">
                <option value="">Alle Phasen</option>
                <option v-for="index in data_stages" :key="index.id" :value="index.id">{{index.name}}</option>
              </select>
              <select v-model="filter_id" class="form-select" v-if="!loading_filter">
                <option value="">Kein Filter</option>
                <option v-for="index in data_filter" :key="index.id" :value="index.id">{{index.name}}</option>
              </select>
              <!-- <div class="skeleton-line me-3" style="min-width: 100px;" v-if="loading_user" ></div> -->
              <div class="skeleton-line me-3 " style="min-width: 100px;" v-if="loading_stages"></div>
              <div class="skeleton-line" style="min-width: 100px;" v-if="loading_filter"></div>
            </div>
          </div>
        </div>
        <div class="table-responsive " style="max-height:500px">
          <table class="table card-table table-vcenter text-nowrap datatable" >
            
            <thead v-if="!loading_table">
              <!-- <tr>
                <th v-for="i in headers_entity" :colspan="getEntry('selected_'+i.key).length" :key="i.key" class="border-end" >{{i.name}}</th>
              </tr> -->
              <draggable tag="tr" v-model="headers" >
                <th class="border-end" v-for="index in headers" :key="index" v-if="selected_headers.includes(index)">{{fields[index].name}}</th>
              </draggable>
            </thead>
            <tbody v-if="!loading_table">
              <tr v-for="item in entries" :key="item.deal.id+'_'+item.deal_product.id">
                <td v-for="key in headers" :key="key" v-if="selected_headers.includes(key)">{{retriveEntry(key,item)}}</td>
              </tr>
            </tbody>
            <thead v-if="loading_table">
              <tr>
                <th class="border-end" colspan="4"><div class="skeleton-line col-3"></div></th>
                <th class="border-end" colspan="4"><div class="skeleton-line col-3"></div></th>
              </tr>
              <tr >
                <th class="border-end" v-for="index in 8" :key="index"><div class="skeleton-line"></div></th>
              </tr>
            </thead>
            <tbody v-if="loading_table">
              <tr>
                <td class="text-center pt-3 pb-3" colspan="8">
                  <div class="spinner-border" role="status"></div>
                </td>
              </tr>
              <tr v-for="index in 8" :key="index">
                <td v-for="index2 in 8" :key="index2"><div class="skeleton-line"></div></td>
              </tr>
            </tbody>
            <tfoot v-if="loading_table">
              <tr class="border-top border-2">
                <th class="border-end" v-for="index in 8" :key="index"><div class="skeleton-line"></div></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="card-footer d-flex align-items-center">
          <p class="m-0 text-muted"></p>
          <ul class="pagination m-0 ms-auto">
            <li class="page-item ">
              <button class="page-link me-2" :disabled="!more">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                Zurück
              </button>
            </li>
            <li class="page-item active"><a class="page-link" href="#">1</a></li>
            <li class="page-item">
              <button class="page-link" :disabled="!more">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="subheader">Export</div>
          </div>
          <div class="d-flex align-items-baseline">
            <button v-if="saveing" class="btn btn-primary" disabled>XLS-Export</button>
            <a href="live/export" v-if="!saveing" class="btn btn-primary" disabled>XLS-Export</a>
          </div>
        </div>
      </div>
      <div class="card" style="">
        <div class="card-body">
          <div class="subheader">Filter</div>
          <div v-if="loading_fields">
            <div class="text-center pt-3 pb-3" colspan="8">
                <div class="spinner-border" role="status"></div>
            </div>
            
            <div v-for="index in 8" :key="index">
              <div class="skeleton-line"></div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordion-example" v-if="!loading_fields">
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-1">
              <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true">
                Deal-Felder
              </button>
            </h2>
            <div id="collapse-1" class="accordion-collapse collapse show" data-bs-parent="#accordion-example">
              <div class="accordion-body ">
                <label class="form-check" v-for="key in headers.filter((el)=>el.startsWith('deal.'))" :key="key"  >
                  <input v-model="selected_headers" :value="key" class="form-check-input" type="checkbox">
                  <span class="form-check-label">{{fields[key].name}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-2">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false">
                Deal-Produkt-Felder
              </button>
            </h2>
            <div id="collapse-2" class="accordion-collapse collapse" data-bs-parent="#accordion-example">
              <div class="accordion-body ">
                <label class="form-check" v-for="key in headers.filter((el)=>el.startsWith('deal_product.'))" :key="key"  >
                  <input v-model="selected_headers" :value="key" class="form-check-input" type="checkbox">
                  <span class="form-check-label">{{fields[key].name}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-3">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false">
                Produkt-Felder
              </button>
            </h2>
            <div id="collapse-3" class="accordion-collapse collapse" data-bs-parent="#accordion-example">
              <div class="accordion-body ">
                <label class="form-check" v-for="key in headers.filter((el)=>el.startsWith('product.'))" :key="key"  >
                  <input v-model="selected_headers" :value="key" class="form-check-input" type="checkbox">
                  <span class="form-check-label">{{fields[key].name}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import axios from "axios";
import draggable from "vuedraggable";

function sorter (sortOrder){
  return ((a, b) => {
  return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  })
}

let flatexport_liveview = {
  name: "ModFlatexportLive",
  components: {
    draggable
  },

  async mounted(){
    let deals = await axios.post('/dash/mod/flatexport/live/deals',{params:{
      filter_id: window.uconfig.filter_id,
      stage_id: window.uconfig.stage_id
    }});
    this.more = deals.data.pagination.more_items_in_collection
    this.entries = deals.data.result
    let fields = await axios.get('/dash/mod/flatexport/live/fields')
    this.fields = fields.data
    for (let field in fields.data){
      if(!this.headers.includes(field) ){
        this.headers = [...this.headers,field]
      }
      // if(field.startsWith("deal.")){
      //   if(!this.headers_deal.includes(field) ){
      //     this.headers_deal = [...this.headers_deal,field]
      //   }
      // }
      // if(field.startsWith("deal_product.")){
      //   if(!this.headers_deal_product.includes(field) ){
      //     this.headers_deal_product = [...this.headers_deal_product,field]
      //   }
      // }
      // if(field.startsWith("product.")){
      //   if(!this.headers_product.includes(field) ){
      //     this.headers_product = [...this.headers_product,field]
      //   }
      // }
    }
    // this.headers_deal = window.uconfig.headers_deal?JSON.parse(window.uconfig.headers_deal):[]
    // this.headers_deal_product = window.uconfig.headers_deal_product?JSON.parse(window.uconfig.headers_deal_product):[]
    // this.headers_product = window.uconfig.headers_product?JSON.parse(window.uconfig.headers_product):[]
    // console.log(this.headers_deal,this.headers_deal_product,this.headers_product)
    // if(this.header == []){

    //   this.headers_deal = this.headers_deal.sort(sorter ( window.uconfig.headers_deal?JSON.parse(window.uconfig.headers_deal):[]))
    //   this.headers_deal_product = this.headers_deal_product.sort(sorter ( window.uconfig.headers_deal_product?JSON.parse(window.uconfig.headers_deal_product):[]))
    //   this.headers_product = this.headers_product.sort(sorter ( window.uconfig.headers_product?JSON.parse(window.uconfig.headers_product):[]))
      
    //   this.header = [...this.headers_deal,...this.headers_deal_product,...this.headers_product]
    // }
    if(!window.uconfig.selected_headers){
      this.selected_headers = Object.keys(fields.data)
    }else{
      this.selected_headers = JSON.parse(window.uconfig.selected_headers)
    }
    this.loading_table = false
    this.loading_fields = false


    this.data_filter = (await axios.get('/dash/mod/flatexport/live/filters')).data.data
    this.loading_filter = false
    // this.data_user = (await axios.get('/dash/mod/flatexport/live/users')).data
    // this.loading_user = false
    this.data_stages = (await axios.get('/dash/mod/flatexport/live/stages')).data.data
    this.loading_stages = false
  },
  methods: {
    moment,
    getEntry(prop){
      return this[prop]
    },
    retriveEntry(str,obj){
      try{
      return str.split('.').reduce((o,i)=> o[i], obj)
      }catch(e){
        return ""
      }
    },
    async update(){
      this.loading_table = true
      let deals = await axios.post('/dash/mod/flatexport/live/deals',{params:{
        filter_id: this.filter_id==""?undefined:this.filter_id,
        stage_id: this.stage_id==""?undefined:this.stage_id,
        start: (this.page-1)*500,
      }});
      this.more = deals.data.pagination.more_items_in_collection
      this.entries = deals.data.result
      this.loading_table = false
    }
  },
  watch:{
    async filter_id(val){
      this.update()
      await axios.post("/dash/mod/flatexport/live/save",{field:"filter_id",content:val})
    },
    async stage_id(){
      this.update()
      await axios.post("/dash/mod/flatexport/live/save",{field:"stage_id",content:val})
    },
    page(){
      this.update()
    },
    async selected_headers(value){
      // this.selected_product=value.filter((val)=>val.startsWith("product.") && this.headers_product.includes(val))
      // this.selected_deal=value.filter((val)=>val.startsWith("deal.")&& this.headers_deal.includes(val))
      // this.selected_deal_product=value.filter((val)=>val.startsWith("deal_product.")&& this.headers_deal_product.includes(val))
      this.saveing = true
      await axios.post("/dash/mod/flatexport/live/save",{field:"selected_headers",content:JSON.stringify(value)})
      this.saveing = false
    },
    async headers(value,old){
      if(value.join(",")!=old.join(",")){
        // let result_deal=[]
        // let result_deal_product=[]
        // let result_product=[]
        
        // for (let field of value){
        //   if(field.startsWith("deal.")){
        //     result_deal.push(field)
        //   }
        //   if(field.startsWith("deal_product.")){
        //     result_deal_product.push(field)
        //   }
        //   if(field.startsWith("product.")){
        //     result_product.push(field)
        //   }
        // }
        // this.header = [...result_deal,...result_deal_product,...result_product]
        // this.header = value
        this.saveing = true
        await axios.post("/dash/mod/flatexport/live/save",{field:"headers",content:JSON.stringify(value)})
        // await axios.post("/dash/mod/flatexport/live/save",{field:"headers_deal",content:JSON.stringify(result_deal)})
        // await axios.post("/dash/mod/flatexport/live/save",{field:"headers_deal_product",content:JSON.stringify(result_deal_product)})
        // await axios.post("/dash/mod/flatexport/live/save",{field:"headers_product",content:JSON.stringify(result_product)})
        this.saveing = false
      }
    }

  },
  data() {
    return {
      settings:{
        active_fields: [],
        ...window.uconfig
      },
      fields: {},
      headers: window.uconfig.headers?JSON.parse(window.uconfig.headers) : [],
      headers_entity:  window.uconfig.headers_entity || [{name:"Deal",key:"deal"},{name:"Deal Produkt",key:"deal_product"},{name:"Produkt",key:"product"}],
      headers_deal: [],
      headers_deal_product: [],
      headers_product: [],
      selected_headers: [],
      entries:[],
      filter_id: window.uconfig.filter_id ? window.uconfig.filter_id :"",
      stage_id: window.uconfig.stage_id ? window.uconfig.stage_id:"",
      data_filter:[],
      data_user:[],
      data_stages:[],
      saveing:true,
      page:1,
      more:false,
      loading_table:true,
      loading_filter:true,
      loading_user:true,
      loading_stages:true,
      loading_fields:true,
    }
  }
};
export default flatexport_liveview
</script>
