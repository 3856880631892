<template>

  <div style="min-width:300px">
    <div v-for="item in result" :key="item.id" class="list-group-item">
      <div class="row align-items-center">
        <div class="col text-truncate">
          <div>
            <span v-for="lab in item.labels" :key="lab"  class="badge bg-blue-lt me-1 mb-1">{{lab}}</span>
          </div>
          <div class="text-body d-block">{{item.name}}</div>
          <small class="d-block text-muted text-truncate mt-n1">{{moment(item.closed_at).format('DD.MM.YYYY')}}</small>
        </div>
      </div>
    </div>

    <ul v-if="result.length == 0" class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="skeleton-avatar"></div>
          </div>
          <div class="col-7">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
          <div class="col-2 ms-auto text-end">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="skeleton-avatar"></div>
          </div>
          <div class="col-7">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
          <div class="col-2 ms-auto text-end">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="skeleton-avatar"></div>
          </div>
          <div class="col-7">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
          <div class="col-2 ms-auto text-end">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="skeleton-avatar"></div>
          </div>
          <div class="col-7">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
          <div class="col-2 ms-auto text-end">
            <div class="skeleton-line"></div>
            <div class="skeleton-line"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
import moment from "moment";
import axios from "axios";

let changelog = {
  name: "Changelog",
  mounted (){
    axios.get('/changelog').then(response => {
      this.result = response.data
    })
  },
  methods: {
    moment
  },
  data() {
    return {result:[]}
  }
};
export default changelog
</script>
