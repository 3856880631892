// import 'bootstrap';
import '@tabler/core';
import { loadStripe } from '@stripe/stripe-js';
import './settings/payment';
import Changelog from '@/components/Changelog';
import ModFlatexportLive from '@/components/Mod/Flatexport/Live';

import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueLazyLoad);

if ($('#changelog').length) {
  new Vue({
    el: '#changelog',
    components: { Changelog },
    template: '<Changelog />'
  });
}

if ($('#mod_flatexport_live').length) {
  new Vue({
    el: '#mod_flatexport_live',
    components: { ModFlatexportLive },
    template: '<ModFlatexportLive />'
  });
}
if ($('#changelogDash').length) {
  new Vue({
    el: '#changelogDash',
    components: { Changelog },
    template: '<Changelog />'
  });
}

if (process.env.NODE_ENV == 'development') {
  loadStripe('pk_test_h4cmQqvxt8IVRQS5PJ2qb0Rg007gmE3D47').then(stripe => { window.stripe = stripe; });
} else {
  loadStripe('pk_live_uNLcmkndUnnKVwrLD3ZNUprS00whiLHQgM').then(stripe => { window.stripe = stripe; });
}
// require('boostrap')

//
